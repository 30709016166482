import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import client from './api-client';
import gql from 'graphql-tag';

const GET_ME = gql`
  query Me {
    me {
      internalUser {
        name
        email
      }
    }
  } 
`;

const UPDATE_ME = gql`
  mutation UpdateMyInternalUser($name: String!) {
    updateMyInternalUser(input: {name: $name}) {
      internalUser {
        name
      }
    }
  }
`;

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      lastSavedName: '',
      email: ''
    };
    this.onNameChange = this.onNameChange.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
  }

  onNameChange(event) {
    this.setState({
      name: event.target.value
    });
  }

  onEmailChange(event) {
    this.setState({
      email: event.target.value
    });
  }

  async updateProfile() {
    const { name } = this.state;
    await client.mutate({
      mutation: UPDATE_ME,
      variables: {
        name: this.state.name
      }
    });
    this.setState({
      lastSavedName: name
    }, () => {
      alert("Alterações salvas com sucesso");
    });
  }

  async componentDidMount() {
    let res = await client.query({
      query: GET_ME
    });
    let {
      name,
      email
    } = res.data.me.internalUser;
    this.setState({
      name,
      email,
      lastSavedName: name
    });
  }

  render() {
    let {
      name,
      email,
      lastSavedName
    } = this.state;
    return (
      <div>
        <Typography variant="h4" gutterBottom component="h2">
          Perfil
        </Typography>
        <div>
          <TextField
            label="Nome"
            placeholder="Nome"
            margin="normal"
            value={name}
            onChange={this.onNameChange}
            style={{
              width: "400px"
            }}
          />
        </div>
        <div>
          <TextField
            label="Email"
            placeholder="Email"
            margin="normal"
            value={email}
            disabled
            onChange={this.onEmailChange}
            style={{
              width: "400px"
            }}
          />
        </div>
        {name === lastSavedName ?
         <Button
           color="primary"
           variant="contained"
           disabled
           size="large"
         >
           Salvar alterações
         </Button>
         :
         <Button
           color="primary"
           variant="contained"
           size="large"
           onClick={this.updateProfile}
         >
           Salvar alterações
         </Button>
        }
        
      </div>
    );
  }
}
