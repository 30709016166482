import React, { Component } from 'react';
import { Route, Link, Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MessageIcon from '@material-ui/icons/Message';
import AssigmentIcon from '@material-ui/icons/Assignment';
import PeopleIcon from '@material-ui/icons/People';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FaceIcon from '@material-ui/icons/Face';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';

import NewMessage from './NewMessage';
import Messages from './Messages';
import Profile from './Profile';
import Polls from './Polls';
import NewPoll from './NewPoll';
import Users from './Users';

import Tooltip from '@material-ui/core/Tooltip';

const drawerWidth = 240;

const MainListItems = ({open}) => (
  <div>
    <MainListItem
      title="Perfil"
      open={open}
      linkUrl="/app/perfil"
    >
      <FaceIcon/>
    </MainListItem>
    <MainListItem
      title="Mensagens"
      open={open}
      linkUrl="/app/mensagens"
    >
      <MessageIcon/>
    </MainListItem>
    <MainListItem
      title="Pesquisas"
      open={open}
      linkUrl="/app/pesquisas"
    >
      <AssigmentIcon/>
    </MainListItem>

    <MainListItem
      title="Usuários"
      open={open}
      linkUrl="/app/usuarios"
    >
      <PeopleIcon/>
    </MainListItem>

    <MainListItem
      marginTop={50}
      title="Sair"
      open={open}
      linkUrl="/app/logout"
    >
      <ExitToAppIcon />
    </MainListItem>
  </div>
);

const MainListItem = ({marginTop, title, open, linkUrl, children}) => {
  if(open) {
    return (
      <Link to={linkUrl}>
        <ListItem
          style={marginTop ? {marginTop} : {}}
          button>
          <ListItemIcon>
            {children}
          </ListItemIcon>
          <ListItemText primary={title} />
        </ListItem>
      </Link>
    );
  }
  
  return (
    <Tooltip title={title}>
      <Link to={linkUrl}>
        <ListItem
          style={marginTop ? {marginTop} : {}}
          button>
          <ListItemIcon>
            {children}
          </ListItemIcon>
          <ListItemText primary={title} />
        </ListItem>
      </Link>
    </Tooltip>
  );
};


const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    height: '100vh',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing.unit * 2,
  },
});


class Panel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
  }

  handleDrawerOpen() {
    this.setState({
      open: true
    });
  }

  handleDrawerClose() {
    this.setState({
      open: false
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
        >
          <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(
                classes.menuButton,
                this.state.open && classes.menuButtonHidden,
              )}
            >
              <MenuIcon />
            </IconButton>

              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                Painel
              </Typography>

          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
          }}
          open={this.state.open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List><MainListItems open={this.state.open}/></List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Route exact path="/app" render={() => <Redirect to="/app/mensagens"/>}/>
          <Route exact path="/app/mensagens" component={Messages}/>
          <Route exact path="/app/mensagens/:messageId" render={props => {
            let messageId = props.match.params.messageId;
            if(messageId === 'nova') {
              return <NewMessage/>;
            }
            return <NewMessage
                     messageId={parseInt(props.match.params.messageId)}
                   />;
          }}/>
          <Route exact path="/app/pesquisas" component={Polls}/>
          <Route exact path="/app/perfil" component={Profile}/>
          <Route exact path="/app/logout" render={() => {
            window.localStorage.removeItem('kuadro-token');
            return (
              <Redirect to="/login"/>
            );
          }}/>
          <Route exact path="/app/pesquisas/:pollId" render={props => {
            console.log(props.match.params);
            let pollId = props.match.params.pollId;
            console.log(pollId);
            if(pollId === 'nova') {
              return <NewPoll/>;
            }
            return <NewPoll
                     pollId={parseInt(props.match.params.pollId)}
                   />;
          }}/>
        <Route exact path="/app/usuarios" component={Users}/>

        </main>
      </div>
    );
  }
}

export default withStyles(styles)(Panel);

