import React, { Component } from 'react';
import { Route, Redirect } from 'react-router';

import Login from './Login';
import Panel from './Panel';

class App extends Component {
  render() {
    return (
      <div>
	<Route exact path="" render={() => <Redirect to="/login"/>} />
        <Route exact path="/login" render={() => {
	  const token = window.localStorage.getItem('kuadro-token');
	  return token ? <Redirect to="/app"/> : <Login/>;
	}} />
        <Route path="/app" render={() => {
          const token = window.localStorage.getItem('kuadro-token');
          return token ? <Panel /> : <Redirect to="/login"/>;
        }} />
      </div>
    );
  }
}

export default App;
