import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink, Observable } from 'apollo-link';

export const API_HOST=`https://pesquisadores-api.fpabramo.org.br`;
const GRAPHQL_ENDPOINT=`/graphql`;

const request = async (operation) => {
  const token = localStorage.getItem('kuadro-token');
  if(token) {
    operation.setContext({
      headers: {
	      'kuadro-token': token,
      },
    })
  }
};

const requestLink = new ApolloLink((operation, forward) => new Observable(observer => {
  let handle;
  Promise.resolve(operation)
    .then(oper => request(oper))
    .then(() => {
      handle = forward(operation).subscribe({
        next: observer.next.bind(observer),
        error: observer.error.bind(observer),
        complete: observer.complete.bind(observer),
      });
    })
    .catch(() => {
      handle = forward(operation).subscribe({
        error: observer.error.bind(observer),
      });
    });

  return () => {
    if (handle) handle.unsubscribe();
  };
}));

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

export default new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    requestLink,
    new HttpLink({
      uri: `${API_HOST}${GRAPHQL_ENDPOINT}`
    }),
  ]),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions
});
