import React, { Component } from 'react';
import { 
    Grid ,
     Dialog,
     DialogTitle,
     DialogContent,
     DialogActions,
     FormControl,
     TextField,
     Button,
     InputLabel,
     Select,
     MenuItem
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import gql from 'graphql-tag';
import client from './api-client';

import validator from './FormValidator';

const CREATE_POLLSTER = gql`
mutation CreatePollster($name: String!, $email: String!, $password: String!, $phone: String, $city: String, $state: String, $cpf: String) {
    createPollster(input: {name: $name, email: $email, password: $password, phone: $phone, city: $city, state: $state, cpf: $cpf }) {
      pollster {
        name
        email
      }
    }
  }
`;

const UPDATE_POLLSTER = gql`
mutation UpdatePollster($id: Int!, $name: String, $email: String, $password: String, $phone: String, $city: String, $state: String, $cpf: String) {
    updatePollster(input: {id: $id, name: $name, email: $email, password: $password, phone: $phone, city: $city, state: $state, cpf: $cpf }) {
      pollster {
        name
        email
      }
    }
  }
`;

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    selectState: {
        minWidth: 120
    },
    input: {
        maxHeight: 85
    }
});

const inputProps = {
    maxLength: 50
};

class EditUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: this.getInitialErrorState(),
            states: [],
            name: '',
            email: '',
            cpf: '',
            phone: '',
            city: '',
            state: '',
            password: '',
            confirmPassword: '',
            editedUser: {}
        }
    }

    componentDidMount() {
        fetch("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
            .then(res => res.json())
            .then(res => {
               this.setState({
                    states: res.map(state => {
                            return { initials: state.sigla, name: state.nome }
                        }).sort((a, b) => {
                            return (a.initials < b.initials) ? -1 : (a.initials > b.initials) ? 1 : 0;
                        })
               });
            });
    }

    componentWillReceiveProps(props) {
        const { id, name, email, cpf, phone, city, state, password, confirmPassword } = props.user;
        this.setState({
            id: id, 
            name: name, 
            email: email,
            cpf: cpf ? cpf: '', 
            phone: phone ? phone : '',
            city: city ? city : '',
            state: state ? state : '',
            password: password ? password : '',
            confirmPassword: confirmPassword ? confirmPassword : '' })
    }

    getInitialErrorState = () => {
        return {
            errorName: {
                error: false,
                message: ''
            },
            errorEmail: {
                error: false,
                message: ''
            },
            errorPassword: {
                error: false,
                message: ''
            },
            errorConfirmPassword: {
                error: false,
                message: ''
            }
        }
    }

    createUser = () => {
        const { name, email, phone, cpf, state, city, password, confirmPassword, errors } = this.state;

        errors.errorName =  validator.validateName(name);
        errors.errorEmail =  validator.validateEmail(email);
        errors.errorPassword =  validator.validatePassword(password);
        errors.errorConfirmPassword =  validator.validateConfirmPassword(confirmPassword, password);

        if (errors.errorName.error || errors.errorEmail.error || errors.errorPassword.error || errors.errorConfirmPassword.error) {
            this.setState({ errors: errors });
        } else {
            this.props.handleClose();
            client.mutate({
                mutation: CREATE_POLLSTER,
                variables: {
                    name: name,
                    email: email,
                    phone: phone,
                    cpf: cpf,
                    state: state,
                    city: city,
                    password: password
                }
            })
                .then(res => {
                    this.props.handleLoadPollsters();
                 })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    updateUser = () => {
        const {editedUser, errors } = this.state;
   
        if (editedUser.id) {
            if (editedUser.name) {
                errors.errorName =  validator.validateName(editedUser.name);
            }
           
            if (editedUser.email) {
                errors.errorEmail =  validator.validateEmail(editedUser.email);
            }

            if (editedUser.password) {
                errors.errorPassword =  validator.validatePassword(editedUser.password);
            }
    
            if (editedUser.confirmPassword) {
                errors.errorConfirmPassword =  validator.validateConfirmPassword(editedUser.confirmPassword, editedUser.password);
            }
          
            if (errors.errorName.error || errors.errorEmail.error || errors.errorPassword.error || errors.errorConfirmPassword.error) {
                this.setState({ errors: errors });
            } else {
                this.props.handleClose();
                client.mutate({
                    mutation: UPDATE_POLLSTER,
                    variables: editedUser
                })
                    .then(res => {
                        this.props.handleLoadPollsters();
                        })
                    .catch(err => {
                        console.log(err);
                    })
            }
        } else {
            this.props.handleClose();
        }
    }

    handleNameChange(event) {
        let { errors, editedUser } = this.state;
        errors.errorName = validator.validateName(event.target.value);

        if (this.state.id) {
            editedUser.id = this.state.id;
            editedUser.name = event.target.value;
        }

        this.setState({
            name: event.target.value,
            editedUser: editedUser,
            errors: errors
        });
    }

    handleEmailChange(event) {
        let { errors, editedUser } = this.state;
        errors.errorEmail = validator.validateEmail(event.target.value);

        if (this.state.id) {
            editedUser.id = this.state.id;
            editedUser.email = event.target.value;
        }

        this.setState({
            email: event.target.value,
            editedUser: editedUser,
            errors: errors
        });
    }

    handleCpfChange(event) {
        let {  editedUser } = this.state;

        if (this.state.id) {
            editedUser.id = this.state.id;
            editedUser.cpf = event.target.value;
        }
        
        this.setState({
            cpf: event.target.value,
            editedUser: editedUser
        });
    }

    handlePhoneChange(event) {
        let { editedUser } = this.state;

        if (this.state.id) {
            editedUser.id = this.state.id;
            editedUser.phone = event.target.value;
        }

        this.setState({
            phone: event.target.value,
            editedUser: editedUser
        });
    }

    handleStateChange(event) {
        let { editedUser } = this.state;

        if (this.state.id) {
            editedUser.id = this.state.id;
            editedUser.state = event.target.value;
        }

        this.setState({
            state: event.target.value,
            editedUser: editedUser
        });
    }

    handleCityChange(event) {
        let { editedUser } = this.state;

        if (this.state.id) {
            editedUser.id = this.state.id;
            editedUser.city = event.target.value;
        }

        this.setState({
            city: event.target.value,
            editedUser: editedUser
        });
    }

    handlePasswordChange(event) {
        let { errors, editedUser } = this.state;
        errors.errorPassword = validator.validatePassword(event.target.value);
        
        if (this.state.id) {
            editedUser.id = this.state.id;
            editedUser.password = event.target.value;
        }

        this.setState({
            password: event.target.value,
            errors: errors,
            editedUser: editedUser
        });
    }

    handleConfirmPasswordChange(event) {
        let { errors, editedUser } = this.state;
        errors.errorConfirmPassword = validator.validateConfirmPassword(event.target.value, this.state.password);

        if (this.state.id) {
            editedUser.id = this.state.id;
            editedUser.confirmPassword = event.target.value;
        }

        this.setState({
            errors: errors,
            confirmPassword: event.target.value,
            editedUser: editedUser
        });
    }

    handleClose() {
        this.setState({ errors : this.getInitialErrorState(), editedUser: {} });
        this.props.handleClose();
    }
   
    render() {
        const { classes } = this.props;
        const { errorName, errorEmail, errorPassword, errorConfirmPassword } = this.state.errors;

        return (
            <Dialog open={this.props.open}>
                <DialogTitle id="form-dialog-title">
                    { this.state.id? 'Editar usuário' : 'Novo usuário' }
                </DialogTitle>
   
                <DialogContent>
                    <Grid container justify="center" spacing={16}>
                            <Grid item xs={7} className={classes.input}>
                                <TextField label="Nome" margin="normal" fullWidth error={errorName.error}
                                                value={this.state.name} onChange={this.handleNameChange.bind(this)}
                                                helperText={errorName.message} inputProps={inputProps}/>
                            </Grid>

                            <Grid item xs={5}>
                                <TextField label="CPF" margin="normal" fullWidth
                                                value={this.state.cpf} onChange={this.handleCpfChange.bind(this)}
                                                inputProps={inputProps}/>
                            </Grid>

                            <Grid item xs={7} className={classes.input}>
                                <TextField label="Email" margin="normal"  fullWidth error={errorEmail.error}
                                                value={this.state.email} onChange={this.handleEmailChange.bind(this)} 
                                                helperText={errorEmail.message} inputProps={inputProps}/>
                            </Grid>

                            <Grid item xs={5}>
                                <TextField label="Telefone" margin="normal" fullWidth
                                                value={this.state.phone} onChange={this.handlePhoneChange.bind(this)}
                                                inputProps={inputProps}/>
                            </Grid>

                            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                                <FormControl>
                                    <InputLabel htmlFor="state">Estado</InputLabel>
                                    <Select
                                    className={classes.selectState}
                                    value={this.state.state}
                                    onChange={this.handleStateChange.bind(this)}
                                    >
                                    {
                                        this.state.states.map(state => {
                                            return (<MenuItem key={state.initials} value={state.initials}>{state.initials}</MenuItem>)
                                        })
                                    }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={8} style={{ display: 'flex', alignItems: 'center' }}>
                                <TextField label="Cidade" margin="normal" fullWidth
                                                value={this.state.city} onChange={this.handleCityChange.bind(this)}
                                                inputProps={inputProps}/>
                            </Grid>

                            <Grid item xs={6} className={classes.input}>
                                <TextField label={ this.state.id? "Alterar senha" : "Senha" } margin="normal" type="password" fullWidth
                                                value={this.state.password} onChange={this.handlePasswordChange.bind(this)}
                                                error={errorPassword.error} inputProps={inputProps}
                                                helperText={errorPassword.message}/>
                            </Grid>
                            <Grid item xs={6} className={classes.input}>
                                <TextField label={ this.state.id? "Confirmar nova senha" : "Confirmar senha" } margin="normal" type="password" fullWidth
                                                value={this.state.confirmPassword} onChange={this.handleConfirmPasswordChange.bind(this)}
                                                error={errorConfirmPassword.error} inputProps={inputProps}
                                                helperText={errorConfirmPassword.message}/>
                            </Grid>
                    </Grid> 
                </DialogContent>
                <DialogActions>
                    <Grid container justify="center">
                        <Grid item xs={4} align="center">
                            <Button onClick={this.handleClose.bind(this)} color="primary">
                                Cancelar
                            </Button>
                        </Grid>
                        <Grid item xs={4} align="center">
                            <Button onClick={this.state.id ? this.updateUser : this.createUser} color="primary">
                                { this.state.id? 'Salvar' :  'Criar' }
                            </Button>
                        </Grid>
                    </Grid>  
                </DialogActions>
            </Dialog>
        );
    }
}


export default withStyles(styles)(EditUser);