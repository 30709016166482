import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import EditUser from './EditUser';

import {
    Typography,
    Tooltip,
     Table,
     TableHead,
     TableRow,
     TableCell,
     TableBody,
     TableFooter,
     TablePagination,
     IconButton,
     CircularProgress,
     Grid,
     Fab,
     Paper,
     InputBase,
     FormControl,
     Select,
     MenuItem,
     Button
} from '@material-ui/core';

import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search'

import gql from 'graphql-tag';
import client from './api-client';

const GET_POLLSTERS = gql`
query GetPollsters($from: Int!, $to: Int!){
    pollsters(from: $from, to: $to) {
        pollsters {
            id
            name
            email
            phone
            city
            state
            cpf
        }
        pagination{
            from
            to
            count
        }
    }
}
`

const SEARCH_USERS = gql`
query PollstersSearch($from: Int!, $to: Int!, $name: String, $email: String, $city: String, $state: String){
    pollstersSearch(from: $from, to: $to, name: $name, email: $email, city: $city, state: $state) {
        pollsters {
            id
            name
            email
            phone
            city
            state
            cpf
        }
        pagination{
            from
            to
            count
        }
    }
}
`

const styles = theme => ({
    progressContainer: {
        height: 200
    },
    searchContainer: {
        display: 'flex'
    },
    input: {
        fontWeight: 'normal'
    },
    tableHeader: {
        height: 40,
    },
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    tableWrapper: {
        overflowX: 'auto'
    }
});  

class Users extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pollsters: [],
            loading: true,
            error: false,
            errorMessage: '',
            openEditUser: false,
            editedUser: { name: '', email: '', phone: '', state: '', city: '', cpf: '', password: '', confirmPassword: '' },
            page: 0,
            rowsPerPage: 10,
            count: 0,
            hasFilters: false,
            filtered: false,
            searchNameValue: '',
            searchEmailValue: '',
            searchStateValue: 'None',
            searchCityValue: '',
            states: []
        }
    }

    componentDidMount() {
        this.loadPollsters();
        this.loadStates();
    }

    loadPollsters = () => {
        const { page, rowsPerPage }  = this.state;

        client.query({
            query: GET_POLLSTERS,
            variables: {
                from: page*rowsPerPage,
                to: page*rowsPerPage + rowsPerPage
            }
        }).then(res => {
            this.setState({ 
                pollsters: res.data.pollsters.pollsters,
                count: res.data.pollsters.pagination.count,
                loading: false,
                filtered: false
            });
        }).catch(err => {
            const message = err.networkError ? 'Erro na conexão com o servidor' : 'Erro ao carregar usuários';
            this.setState({ 
                loading: false,
                error: true,
                errorMessage: message,
                pollsters: [],
                filtered: false
            });
        });
    }

    loadStates() {
        fetch("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
        .then(res => res.json())
        .then(res => {
           this.setState({
                states: res.map(state => {
                        return { initials: state.sigla, name: state.nome }
                    }).sort((a, b) => {
                        return (a.initials < b.initials) ? -1 : (a.initials > b.initials) ? 1 : 0;
                    })
           });
        });
    }

    openNewUser = () => {
        const editedUser = {
            name: '',
            email: '',
            phone: '',
            state: '',
            city: '',
            cpf: '',
            password: '',
            confirmPassword: ''
        }

        this.setState({ 
            openEditUser: true,
            editedUser: editedUser
        })
    }

    openEditUser = (user) => {
        const editedUser = {
            id: user.id,
            name: user.name,
            email: user.email,
            phone: user.phone,
            state: user.state,
            city: user.city,
            cpf: user.cpf,
            password: '',
            confirmPassword: ''
        };

        this.setState({ 
            openEditUser: true,
            editedUser: editedUser
        });
    }

    handleCloseEditUser = () => {
        this.setState({ openEditUser: false })
    }

    handleLoadPollsters = () => {
        this.loadPollsters();
    }

    TablePaginationActions(props) {
        const { count, page, rowsPerPage, onChangePage } = props;

        function handleFirstPageButtonClick(event) {
          onChangePage(event, 0);
        }

        function handleBackButtonClick(event) {
          onChangePage(event, page - 1);
        }

        function handleNextButtonClick(event) {
          onChangePage(event, page + 1);
        }

        function handleLastPageButtonClick(event) {
          onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        }

        return (
            <div style={{ flexShrink: 0 }}>
                <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0}>
                    <FirstPageIcon />
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
                    <KeyboardArrowLeft />
                </IconButton>
                <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
                    <KeyboardArrowRight />
                </IconButton>
                <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
                    <LastPageIcon />
                </IconButton>
            </div>
        );
    }
    
    handleChangePage(event, newPage) {
        this.setState({ 
            page: newPage
        }, () => {
            if (this.state.hasFilters) {
                this.searchUser();
            } else {
                this.loadPollsters();
            }
        });
    }
    
    handleChangeRowsPerPage(event) {
        this.setState({
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10)
        }, () => {
            if (this.state.hasFilters) {
                this.searchUser();
            } else {
                this.loadPollsters();
            }
        });
    }

    getHasFilters() {
        const { searchNameValue, searchEmailValue, searchStateValue, searchCityValue } = this.state;

        return (
            searchNameValue.trim().length > 0 ||
            searchEmailValue.trim().length > 0 ||
            searchStateValue !== 'None' ||
            searchCityValue.trim().length > 0
        );
    }

    handleChangeSearchName(event) {
        this.setState({ 
            searchNameValue: event.target.value,
        }, () => {
            this.setState({ hasFilters: this.getHasFilters() })
        });
    }

    handleChangeSearchEmail(event) {
        this.setState({ 
            searchEmailValue: event.target.value,
        }, () => {
            this.setState({ hasFilters: this.getHasFilters() });
        });
    }

    handleStateChange(event) {
        this.setState({ 
            searchStateValue: event.target.value,
        }, () => {
            this.setState({ hasFilters: this.getHasFilters() });
        });
    }

    handleChangeSearchCity(event) {
        this.setState({ 
            searchCityValue: event.target.value,
        }, () => {
            this.setState({ hasFilters: this.getHasFilters() });
        });
    }

    clearFilters = () => {
        this.setState({
            hasFilters: false,
            loading: true,
            page: 0,
            rowsPerPage: 10,
            searchNameValue: '',
            searchEmailValue: '',
            searchStateValue: 'None',
            searchCityValue: ''
        }, () => {
            this.loadPollsters();
        });

    }

    searchUser = (e) => {
        if (e) {
            e.preventDefault();
        }

        const { page, rowsPerPage, searchNameValue, searchEmailValue, searchStateValue, searchCityValue } = this.state;
        let variables = {
            from: page*rowsPerPage,
            to: page*rowsPerPage + rowsPerPage
        };

        if (searchNameValue.trim().length !== 0) {
            variables.name = searchNameValue;
        };

        if (searchEmailValue.trim().length !== 0) {
            variables.email = searchEmailValue;
        }

        if (searchStateValue !== 'None') {
            variables.state = searchStateValue;
        }

        if (searchCityValue.trim().length !== 0) {
            variables.city = searchCityValue;
        }

        if (variables.name || variables.email || variables.state || variables.city) {
            this.setState({ loading: true });
            client.query({
                query: SEARCH_USERS,
                variables: variables
            })
                .then(res => {
                    this.setState({ 
                        pollsters: res.data.pollstersSearch.pollsters,
                        count: res.data.pollstersSearch.pagination.count,
                        loading: false,
                        filtered: true
                    });
                })
                .catch(err => {
                    const message = err.networkError ? 'Erro na conexão com o servidor' : 'Erro ao carregar usuários';
                    this.setState({ 
                        loading: false,
                        error: true,
                        errorMessage: message,
                        pollsters: [],
                        filtered: false
                    });
                });
        }
    }

    render () {
        const { classes } = this.props;
        const { count, page, rowsPerPage }  = this.state;

        return (
            <div>
                <EditUser
                 open={this.state.openEditUser}
                 user={this.state.editedUser}
                 handleClose={this.handleCloseEditUser.bind(this)}
                 handleLoadPollsters={this.handleLoadPollsters.bind(this)}
                />
                <Grid container>
                    <Grid item xs={2}>
                        <Typography variant="h4" gutterBottom component="h2">
                            Usuários
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title="Criar usuário">
                            <div style={{ float: 'left' }}>
                                <Fab size="small" color="primary" onClick={this.openNewUser} 
                                disabled={this.state.loading || this.state.error}
                                >
                                    <AddIcon/>
                                </Fab>
                            </div>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Paper>
                    <div className={classes.tableWrapper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        {
                                            this.state.filtered && 
                                            <Button color="primary" className={classes.button} onClick={this.clearFilters}>
                                                Limpar filtros
                                            </Button>
                                        }
                                        
                                    </TableCell>
                                    <TablePagination
                                            classes={{ padding: 0 }}
                                            rowsPerPageOptions={[5, 10, 25]}
                                            colSpan={4}
                                            count={count}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            labelRowsPerPage={'Usuários por página:'}
                                            onChangePage={this.handleChangePage.bind(this)}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                                            ActionsComponent={this.TablePaginationActions}
                                            labelDisplayedRows={() => 
                                                `${page*rowsPerPage+1}-${page*rowsPerPage+this.state.pollsters.length} de ${count}`}
                                        />
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">
                                        <form  onSubmit={this.searchUser}>
                                            <div className={classes.searchContainer}>
                                                <InputBase
                                                    className={classes.input}
                                                    value={this.state.searchNameValue}
                                                    onChange={this.handleChangeSearchName.bind(this)}
                                                    placeholder="Pesquisar nome..."/>
                                            </div>
                                        </form>
                                    </TableCell>
                                    <TableCell align="left">
                                        <form  onSubmit={this.searchUser}>
                                            <div className={classes.searchContainer}>
                                                <InputBase 
                                                    className={classes.input} 
                                                    value={this.state.searchEmailValue}
                                                    onChange={this.handleChangeSearchEmail.bind(this)}
                                                    placeholder="Pesquisar email..."/>
                                            </div>
                                        </form>
                                    </TableCell>
                                    <TableCell style={{ paddingRight: 0, paddingLeft: 0 }}>
                                            <FormControl fullWidth>
                                                <Select disableUnderline 
                                                    style={{ fontWeight: '400' }}
                                                    autoWidth={true}
                                                    className={classes.selectState}
                                                    value={this.state.searchStateValue}
                                                    onChange={this.handleStateChange.bind(this)}
                                                >
                                                    <MenuItem value={'None'}>
                                                        <span style={{ color: 'rgba(0, 0, 0, 0.35)' }}>Todos estados</span>
                                                    </MenuItem>
                                                {
                                                    this.state.states.map(state => {
                                                        return (<MenuItem key={state.initials} value={state.initials}>{state.initials}</MenuItem>)
                                                    })
                                                }
                                                </Select>
                                            </FormControl>
                                    </TableCell>
                                    <TableCell>
                                        <form  onSubmit={this.searchUser}>
                                            <div className={classes.searchContainer}>
                                                <InputBase
                                                    className={classes.input}
                                                    value={this.state.searchCityValue}
                                                    onChange={this.handleChangeSearchCity.bind(this)}
                                                    placeholder="Pesquisar cidade..."/>
                                            </div>
                                        </form>
                                    </TableCell>
                                    <TableCell>
                                            <IconButton className={classes.iconButton} aria-label="Search" onClick={this.searchUser}>
                                                <SearchIcon />
                                            </IconButton>
                                    </TableCell>
                                </TableRow>
                                <TableRow className={classes.tableHeader}>
                                    <TableCell className={classes.tableHeaderCell} align="left">Nome</TableCell>
                                    <TableCell className={classes.tableHeaderCell} align="left">Email</TableCell>
                                    <TableCell className={classes.tableHeaderCell} align="left">Estado</TableCell>
                                    <TableCell className={classes.tableHeaderCell} align="left">Cidade</TableCell>
                                    <TableCell className={classes.tableHeaderCell} align="left">Editar</TableCell>
                                </TableRow>
                            </TableHead>
                            {
                                this.state.error && 
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={7}>
                                            <Grid container justify="center" alignItems="center" className={classes.progressContainer}>
                                                { this.state.errorMessage }
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            }
                            {
                                this.state.loading && 
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={5}>
                                            <Grid container justify="center" alignItems="center" className={classes.progressContainer}><CircularProgress></CircularProgress></Grid>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            }
                            <TableBody>
                                { 
                                    !this.state.loading && 
                                    this.state.pollsters.map((user) => (
                                        <TableRow key={user.id}>
                                            <TableCell align="left">{ user.name }</TableCell>
                                            <TableCell align="left">{ user.email }</TableCell>
                                            <TableCell align="left">{ user.state }</TableCell>
                                            <TableCell align="left">{ user.city }</TableCell>
                                            <TableCell align="left">
                                                <IconButton size="small" color="primary" onClick={() => this.openEditUser(user)}>
                                                    <Tooltip title="Editar usuário"><EditIcon/></Tooltip>
                                                </IconButton >
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell colSpan={2}></TableCell>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        colSpan={5}
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        labelRowsPerPage={'Usuários por página:'}
                                        onChangePage={this.handleChangePage.bind(this)}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                                        ActionsComponent={this.TablePaginationActions}
                                        labelDisplayedRows={() => 
                                            `${page*rowsPerPage+1}-${page*rowsPerPage+this.state.pollsters.length} de ${count}`}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </div>
                </Paper>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(Users));