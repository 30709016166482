import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Downshift from 'downshift';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => ({
    container: {
        flexGrow: 1,
        position: 'relative'
    },
    paper: {
        position: 'absolute',
        zIndex: 2000,
        left: 0,
        right: 0,
    },
    inputRoot: {
        flexWrap: 'wrap',
        width: 360
    },
    inputInput: {
        width: 'auto',
        flexGrow: 1,
    }
});

  function renderInput(inputProps) {
    const { value, InputProps, classes, ref, selectedItem, ...other } = inputProps;
    return (
      <TextField
        autoFocus
        value={value}
        InputProps={{
            inputRef: ref,
            classes: {
                root: classes.inputRoot,
                input: classes.inputInput,
            },
            ...InputProps,
        }}
        {...other}
      />
    );
}

function renderSuggestion(suggestionProps) {
    const { suggestion, index, itemProps, highlightedIndex } = suggestionProps;
    const isHighlighted = highlightedIndex === index;
  
    return (
      <MenuItem
        {...itemProps}
        value={suggestion.id}
        key={suggestion.id}
        selected={isHighlighted}
        component="div"
      >
        {suggestion.email}
      </MenuItem>
    );
}

function getSuggestions(suggestions, value, { showEmpty = false } = {}) {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
  
    return inputLength === 0 && !showEmpty
      ? []
      : suggestions.slice(0, 10)
}

function itemToString(i) {
    return (i ? i.email : '');
}

class AutoCompletePollsters extends Component {
    render () {
        const { classes } = this.props;

        return (
            <Downshift
            id="downshift-simple"
            itemToString={itemToString}
            onChange={this.props.handleChangeValue}
            selectedItem={this.props.value}
            inputValue={this.props.inputValue}
            onInputValueChange={this.props.onInputValueChange}>
                {({
                    clearSelection,
                    getInputProps,
                    getItemProps,
                    highlightedIndex,
                    inputValue,
                    isOpen,
                    selectedItem,
                    }) => {
                    const { onBlur, onFocus, ...inputProps } = getInputProps({
                        onChange: event => {
                            if (event.target.value === null) {
                              clearSelection();
                            }
                          }
                    });

                    return (
                        <div className={classes.container}>
                            {renderInput({
                                onChange: this.props.onInputChange,
                                value: inputValue,
                                selectedItem: selectedItem,
                                fullWidth: true,
                                classes,
                                label: 'Email do pesquisador',
                                InputProps: { 
                                    onBlur,
                                    onFocus},
                                inputProps,
                            })}

                            <div>
                                {isOpen ? (
                                <Paper className={classes.paper} square>
                                    {getSuggestions(this.props.suggestions, inputValue).map((suggestion, index) =>
                                        renderSuggestion({
                                            suggestion,
                                            index,
                                            itemProps: getItemProps({ item: suggestion }),
                                            highlightedIndex,
                                            selectedItem,
                                        }),
                                    )}
                                </Paper>
                                ) : null}
                            </div>
                        </div>
                    );
                }}
            </Downshift>
        );
    }
}

export default withStyles(styles)(AutoCompletePollsters);