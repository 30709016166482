import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment-timezone';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';

import { withStyles } from '@material-ui/core/styles';

const GET_POLLS = gql`
  {
    polls{
      id
      title
      author {
        name
      }
      createdAt
      publishAt
      startAt
      endAt
    }
  }
`;

const styles = theme => ({
  addIcon: {
    marginLeft: "10px",
    cursor: "pointer",
    color: "blue"
  }
});


class Polls extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Typography variant="h4" gutterBottom component="h2">
          Pesquisas
        </Typography>
        <Tooltip title="Criar pesquisa">
          <Link to="/app/pesquisas/nova">
            <AddCircleIcon
              className={classes.addIcon}
            />
          </Link>
        </Tooltip>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Título</TableCell>
              <TableCell align="left">Autor</TableCell>
              <TableCell align="left">Publicação</TableCell>
              <TableCell align="left">Início</TableCell>
              <TableCell align="left">Fim</TableCell>
              <TableCell align="left">Editar</TableCell>
            </TableRow>
          </TableHead>
          <Query query={GET_POLLS}>
            {({ loading, error, data }) => {
              if (loading) return (
                <TableBody>
                  <TableRow>
                    <TableCell>Carregando...</TableCell>
                  </TableRow>
                </TableBody>
              );
              if (error) return `Error! ${error.message}`;
              let polls = data.polls;
              return (
                <TableBody>{polls.map(poll => (<PollRow key={poll.id} poll={poll}/>))}</TableBody>
              );
            }}
          </Query>
        </Table>

      </div>
    );
  }
}

class PollRow extends Component {
  formatDateStr(s) {
    return moment(s).tz("America/Sao_Paulo").format("DD/MM/YYYY HH:mm");
  }
  render() {
    let { poll } = this.props;
    return (
      <TableRow>
        <TableCell component="th" scope="row" style={{fontWeight: "bold"}}>{poll.title}</TableCell>
        <TableCell align="left">{poll.author.name}</TableCell>
        <TableCell align="left">{this.formatDateStr(poll.publishAt)}</TableCell>
        <TableCell align="left">{this.formatDateStr(poll.startAt)}</TableCell>
        <TableCell align="left">{this.formatDateStr(poll.endAt)}</TableCell>
        <TableCell><Tooltip title="Editar pesquisa"><Link to={`/app/pesquisas/${poll.id}`}><EditIcon style={{color: "#000"}}/></Link></Tooltip></TableCell>
      </TableRow>
    );
  }
}

export default withStyles(styles)(Polls);
