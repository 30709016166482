import React, { Component } from 'react';

import TextField from '@material-ui/core/TextField';
import InputBase from '@material-ui/core/InputBase';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Radio from '@material-ui/core/Radio';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import MenuItem from '@material-ui/core/MenuItem';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import client from './api-client';
import Tooltip from '@material-ui/core/Tooltip';

import Select from '@material-ui/core/Select';
import gql from 'graphql-tag';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment-timezone';


import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import SendIcon from '@material-ui/icons/Send';

import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { API_HOST } from './api-client';
import { FormControlLabel, List, ListItem, ListItemText, Paper, ListItemSecondaryAction, ListSubheader } from '@material-ui/core';
import AutoCompletePollsters from './AutoCompletePollsters';

const styles = theme => ({
  questionsTitle: {
    marginTop: '30px'
  },
  addIcon: {
    cursor: "pointer",
    color: "blue"
  },
  saveButton: {
    marginTop: '30px'
  }
});

let CREATE_POLL = gql`
  mutation CreatePoll(
    $title: String!, 
    $publishAt: DateTime!, 
    $startAt: DateTime!, 
    $endAt: DateTime!,
    $multipleChoiceQuestions: [MultipleChoiceQuestionInput!]!,
    $writtenQuestions: [WrittenQuestionInput!]!,
    $checkboxQuestions: [CheckboxQuestionInput!]!,
    $pollsters: [PollPollstersInput]) {
    createPoll(input: {title: $title, publishAt: $publishAt, startAt: $startAt, endAt: $endAt, multipleChoiceQuestions: $multipleChoiceQuestions, writtenQuestions: $writtenQuestions, checkboxQuestions: $checkboxQuestions, pollsters: $pollsters}) {
      poll {
        id
        title
      }
    }
}`;

let UPDATE_POLL = gql`
  mutation UpdatePoll(
    $id: Int!, 
    $title: String!, 
    $publishAt: DateTime!, 
    $startAt: DateTime!, 
    $endAt: DateTime!, 
    $multipleChoiceQuestions: [CreateOrUpdateMultipleChoiceQuestionInput!]!,
    $writtenQuestions: [CreateOrUpdateWrittenQuestionInput!]!,
    $checkboxQuestions: [CreateOrUpdateCheckboxQuestionInput!]!,
    $pollsters: [PollPollstersInput]) {
    updatePoll(input: {id: $id, title: $title, publishAt: $publishAt, startAt: $startAt, endAt: $endAt, multipleChoiceQuestions: $multipleChoiceQuestions, writtenQuestions: $writtenQuestions, checkboxQuestions: $checkboxQuestions, pollsters: $pollsters}) {
      poll {
        id
        title
      }
    }
  }
`;

let GET_POLL = gql`
  query GetPoll($id: Int!)  {
    poll(id:$id) {
      title
      publishAt
      startAt
      endAt
      answersURL
      questions {
        id
        body
        position
        __typename
        ...on MultipleChoiceQuestion {
          alts1: alternatives {
            id
            position
            body
          }           
        }
        ...on CheckboxQuestion {
          alts2: alternatives {
            id
            position
            body
          }
        }
        
      }
      pollsters {
        id
        email
        name
      }
    }
  }
`;

const SEARCH_USERS = gql`
  query PollstersSearch($from: Int!, $to: Int!, $email: String){
      pollstersSearch(from: $from, to: $to, email: $email) {
          pollsters {
              id
              name
              email
          }
      }
  }
`

class SimpleTabs extends React.Component {
  constructor(props) {
    super(props);
    console.log(`${moment.tz("America/Sao_Paulo").utcOffset()}`);
    this.state = {
      tabIdx: 0,
      title: '',
      publishAt: '',
      startAt: '',
      endAt: '',
      answersURL: '',
      questions: [
        {
          body: 'Pergunta 1',
          type: 'MultipleChoiceQuestion',
          position: 1,
          alternatives: [
            {
              body: 'Opção 1',
              position: 1
            }
          ]
        }
      ],
      selectedQuestionIdx: 0,
      allPollsters: true,
      pollsters: [],
      pollstersSuggestions: [],
      pollster: null,
      showInput: false
    };
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onPublishAtChange = this.onPublishAtChange.bind(this);
    this.onStartAtChange = this.onStartAtChange.bind(this);
    this.onEndAtChange = this.onEndAtChange.bind(this);
    this.onTabIdxChange = this.onTabIdxChange.bind(this);
    this.onNewAlternative = this.onNewAlternative.bind(this);
    this.onBodyChange = this.onBodyChange.bind(this);
    this.deleteSelectedQuestion = this.deleteSelectedQuestion.bind(this);
    this.deleteSelectedQuestionAlternative = this.deleteSelectedQuestionAlternative.bind(this);
    this.onNewQuestion = this.onNewQuestion.bind(this);
    this.onSelectQuestion = this.onSelectQuestion.bind(this);
    this.createPoll = this.createPoll.bind(this);
    this.updatePoll = this.updatePoll.bind(this);
    this.onAlternativeChange = this.onAlternativeChange.bind(this);
    this.onQuestionTypeChange = this.onQuestionTypeChange.bind(this);

    this.onChangeAllPollsters = this.onChangeAllPollsters.bind(this);
    this.onChangePollster= this.onChangePollster.bind(this);
    this.onChangeInputPollster = this.onChangeInputPollster.bind(this);
    this.addPollster = this.addPollster.bind(this);
    this.toggleShowInput = this.toggleShowInput.bind(this);
    this.removePollster = this.removePollster.bind(this);

    this.timeoutHandle = null;
  }

  isoToTextFieldRepr(s) {
    return moment(s).tz("America/Sao_Paulo").format("YYYY-MM-DDTHH:mm");
  }

  textFieldToIsoRepr(s) {
    return `${moment(s, "YYYY-MM-DDTHH:mm").tz('Etc/UTC').format("YYYY-MM-DDTHH:mm")}Z`;
  }

  componentDidMount() {
    let { pollId } = this.props;
    if(!pollId) {
      return;
    }
    client.query({
      query: GET_POLL,
      variables: {
        id: pollId
      }
    })
      .then(res => {
        let poll = res.data.poll;
        let qs = poll.questions.map(q => ({
          ...q,
          type: q["__typename"],
          alternatives: q.alts1 || q.alts2
        }));

        this.setState({
          title: poll.title,
          publishAt: this.isoToTextFieldRepr(poll.publishAt),
          startAt: this.isoToTextFieldRepr(poll.startAt),
          endAt: this.isoToTextFieldRepr(poll.endAt),
          answersURL: poll.answersURL,
          questions: qs,
          pollsters: poll.pollsters ? poll.pollsters : [],
          allPollsters: poll.pollsters === null
        });
      })
      .catch(err => {
      });
  }

  deleteSelectedQuestion() {
    this.setState((state, props) => {
      const { questions, selectedQuestionIdx } = state;
      questions.splice(selectedQuestionIdx, 1);
      return {
        questions
      };
    });
  }

  deleteSelectedQuestionAlternative(alternativeIndex) {
    this.setState((state, props) => {
      const { questions, selectedQuestionIdx } = state;
      questions[selectedQuestionIdx].alternatives.splice(alternativeIndex, 1);
      return {
        questions
      };
    });
  }

  _filterQuestionsWithAlternative(qType) {
    return this.state.questions.filter(q => q.type === qType).map((el, idxQ) => {
          let payload = {
            body: el.body,
            position: el.position,
            alternatives: el.alternatives.map((x, idx) => {
              let p = {
                body: x.body,
                position: x.position
              };
              if(x.id) {
                p.id = x.id;
              }
              return p;
            })
          };
          if(el.id) {
            payload.id = el.id;
          }
          return payload;
    });
  }

  async updatePoll() {
    const { pollId } = this.props;
    const { title, publishAt, startAt, endAt, allPollsters, pollsters } = this.state;
    let multipleChoiceQuestions = this._filterQuestionsWithAlternative("MultipleChoiceQuestion");
    let checkboxQuestions = this._filterQuestionsWithAlternative("CheckboxQuestion");
    let writtenQuestions = this.state.questions.filter(q => q.type === "WrittenQuestion").map(el => {
      let payload = {
        body: el.body,
        position: el.position
      };
      if(el.id) {
        payload.id = el.id;
      }
      return payload;
    });

    let variables = {
      id: pollId,
      title: title,
      publishAt: this.textFieldToIsoRepr(publishAt),
      startAt:this.textFieldToIsoRepr(startAt),
      endAt: this.textFieldToIsoRepr(endAt),
      multipleChoiceQuestions: multipleChoiceQuestions,
      writtenQuestions: writtenQuestions,
      checkboxQuestions: checkboxQuestions
    }

    if (!allPollsters) {
      variables.pollsters = pollsters.map(pollster => { return { pollster_id: pollster.id } });
    }

    await client.mutate({
      mutation: UPDATE_POLL,
      variables: variables
    });
    this.props.history.push('/app/pesquisas');
  }

  _localeToISOString(date) {
    console.log("Converting", date);
    return `${moment.tz(date.replace("T"," "), "America/Sao_Paulo").tz('Etc/UTC').format('YYYY-MM-DDTHH:mm')}Z`;
  }

  async createPoll() {
    const { title, publishAt, startAt, endAt, allPollsters, pollsters } = this.state;

    if (this.state.allPollsters || this.state.pollsters.length > 0) {
      let multipleChoiceQuestions = this._filterQuestionsWithAlternative("MultipleChoiceQuestion");
      let checkboxQuestions = this._filterQuestionsWithAlternative("CheckboxQuestion");
      let writtenQuestions = this.state.questions.filter(q => q.type === "WrittenQuestion").map(el => {
        let payload = {
          body: el.body,
          position: el.position
        };
        if(el.id) {
          payload.id = el.id;
        }
        return payload;
      });
      let variables = {
        title: title,
        publishAt: this.textFieldToIsoRepr(publishAt),
        startAt: this.textFieldToIsoRepr(startAt),
        endAt: this.textFieldToIsoRepr(endAt),
        multipleChoiceQuestions,
        checkboxQuestions,
        writtenQuestions
      }

      if (!allPollsters) {
        variables.pollsters = pollsters.map(pollster => { return { pollster_id: pollster.id } });
      }

      await client.mutate({
        mutation: CREATE_POLL,
        variables: variables
      });
      this.props.history.push('/app/pesquisas');
    }
  }

  onSelectQuestion(index) {
    this.setState({
      selectedQuestionIdx: index
    });
  }

  onAlternativeChange(questionIdx, alternativeIdx, body) {
    this.setState((state, props) => {
      let questions = state.questions;
      questions[questionIdx].alternatives[alternativeIdx].body = body;
      return {
        questions
      };
    });
  }

  onBodyChange(event) {
    let questions = this.state.questions;
    questions[this.state.selectedQuestionIdx].body = event.target.value;
    this.setState({
      questions: questions
    });
  }

  onNewAlternative(event) {
    this.setState((state, props) => {
      let questions = state.questions;
      let selectedQuestion = questions[state.selectedQuestionIdx];
      let newAlt = {
        body: `Opção ${selectedQuestion.alternatives.length+1}`,
        position: selectedQuestion.alternatives.length+1
      };
      selectedQuestion.alternatives = selectedQuestion.alternatives.concat([newAlt]);
      return {
        questions: questions
      };
    });
  }

  onNewQuestion(event) {
    this.setState((state, props) => {
      let newQuestion = {
        body: `Pergunta ${state.questions.length + 1}`,
        position: state.questions.length+1,
        type: "MultipleChoiceQuestion",
        alternatives: [
          {
            body: 'Opção 1',
            position: 1
          }
        ]
      };
      return {
        questions: state.questions.concat(newQuestion),
        selectedQuestionIdx: state.questions.length
      };
    });
  }

  onQuestionTypeChange(event) {
    this.setState((state, props) => {
      const { questions, selectedQuestionIdx } = state;
      questions[selectedQuestionIdx].type = event.target.value;
      this.setState({
        questions
      });
    });
  }

  onTitleChange(event) {
    this.setState({
      title: event.target.value
    });
  }

  onPublishAtChange(event) {
    this.setState({
      publishAt: event.target.value
    });
  }

  onStartAtChange(event) {
    this.setState({
      startAt: event.target.value,
    });
  }

  onEndAtChange(event) {
    this.setState({
      endAt: event.target.value,
    });
  }  
  
  onTabIdxChange(event, value) {
    this.setState({ tabIdx: value });
  };

  onChangeAllPollsters(event, value) {
    this.setState({ allPollsters: value });
  }

  onChangePollster(value) {
    this.setState({ pollster: value });
  }

  onChangeInputPollster(value) {
    clearTimeout(this.timeoutHandle);
    if (value.trim() !== '') {
      this.timeoutHandle = setTimeout(() => {
        this.searchPollsters(value);
      }, 300);
    }
  }

  searchPollsters(email) {
    client.query({
      query: SEARCH_USERS,
      variables: {
        email: email,
        from: 0,
        to: 20
      }
    })
      .then(res => {
        this.setState({
          pollstersSuggestions: res.data.pollstersSearch.pollsters.map(pollster => {
            return { id: pollster.id, email: pollster.email, name: pollster.name };
          })
        });
      });
  }

  addPollster(e) {
    e.preventDefault();
    if (this.state.pollster) {
      if (!this.state.pollsters.some(pollster => pollster.id === this.state.pollster.id)) {
        let { pollsters } = this.state;
        pollsters.push(this.state.pollster);
        this.setState({
          pollsters: pollsters,
          pollstersSuggestions: [],
          pollster: null
        });
      }
    }
  }

  removePollster(id) {
    let { pollsters } = this.state;
    const pollsterIdx = this.state.pollsters.findIndex(pollster => {
      return pollster.id === id
    });
    pollsters.splice(pollsterIdx, 1);
    this.setState({ pollsters: pollsters });
  }

  toggleShowInput() {
    this.setState({ showInput: !this.state.showInput })
  }

  render() {
    this.onNewAlternative = this.onNewAlternative.bind(this);
    this.onBodyChange = this.onBodyChange.bind(this);
    this.onNewQuestion = this.onNewQuestion.bind(this);
    this.onSelectQuestion = this.onSelectQuestion.bind(this);
    const { classes, pollId } = this.props;
    const { title, publishAt, startAt, endAt, tabIdx } = this.state;

    return (
      <div className={classes.root}>
        <Tabs value={tabIdx} onChange={this.onTabIdxChange}>
          <Tab label="Descrição" />
          <Tab label="Perguntas" />
          <Tab label="Respostas" />
          <Tab label="Pesquisadores" />
        </Tabs>
        {tabIdx === 0 &&
         <PollDescription
           title={title}
           publishAt={publishAt}
           startAt={startAt}
           endAt={endAt}
           onTitleChange={this.onTitleChange}
           onPublishAtChange={this.onPublishAtChange}
           onStartAtChange={this.onStartAtChange}
           onEndAtChange={this.onEndAtChange}
         />}
        {tabIdx === 1 &&
         <Questions
           questions={this.state.questions}
           deleteSelectedQuestion={this.deleteSelectedQuestion}
           deleteAlternative={this.deleteSelectedQuestionAlternative}
           selectedQuestionIdx={this.state.selectedQuestionIdx}
           onNewAlternative={this.onNewAlternative}
           onQuestionTypeChange={this.onQuestionTypeChange}
           onAlternativeChange={this.onAlternativeChange}
           onBodyChange={this.onBodyChange}
           onNewQuestion={this.onNewQuestion}
           onSelectQuestion={this.onSelectQuestion}
         />
        }
        {tabIdx === 2 &&
         <Answers
           link={this.state.answersURL}
         />}

        {tabIdx === 3 &&
          <Pollsters
            allPollsters={this.state.allPollsters}
            onChangeAllPollsters={this.onChangeAllPollsters}
            onChangePollster={this.onChangePollster}
            onChangeInputPollster={this.onChangeInputPollster}
            pollster={this.state.pollster}
            pollsters={this.state.pollsters}
            pollstersSuggestions={this.state.pollstersSuggestions}
            addPollster={this.addPollster}
            showInput={this.state.showInput}
            toggleShowInput={this.toggleShowInput}
            removePollster={this.removePollster}
          />
        }
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={pollId? this.updatePoll : this.createPoll}
          style={{
            position: "absolute",
            bottom: "30px",
            left: "150px"
          }}
        >
          {pollId ? "Salvar pesquisa" : "Criar pesquisa"}
        </Button>
        <div style={{
            position: "absolute",
            bottom: "42px",
            left: "350px",
            color: "red"
          }}>
          { !this.state.allPollsters && this.state.pollsters.length === 0 && 'Nenhum pesquisador incluso' }
        </div>
      </div>
    );
  }
}

class PollDescription extends React.Component {
  render() {
    const { title, publishAt, startAt, endAt } = this.props;
    return (
      <div>
        <div>
          <TextField
            label="Título"
            placeholder="Título"
            value={title}
            onChange={this.props.onTitleChange}
            margin="normal"
          />
        </div>
        <div>
          <TextField
            label="Data de publicação"
            type="datetime-local"
            value={publishAt}
            onChange={this.props.onPublishAtChange}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        <div>
          <TextField
            label="Data de início"
            type="datetime-local"
            value={startAt}
            onChange={this.props.onStartAtChange}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        <div>
          <TextField
            label="Data de término"
            type="datetime-local"
            value={endAt}
            onChange={this.props.onEndAtChange}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
      </div>
    );
  }
}

class Questions extends Component {
  render() {
    let {
      selectedQuestionIdx,
      onNewAlternative,
      onAlternativeChange,
      onBodyChange,
      onNewQuestion,
      onSelectQuestion,
      deleteSelectedQuestion,
      deleteAlternative,
      onQuestionTypeChange
    } = this.props;
    console.log("Rendering...");
    return (
      <div>
        <div>
          {this.props.questions.map((q,idx) => {
            if(idx === selectedQuestionIdx) {
              return (
                <Question
                  key={idx}
                  body={q.body}
                  type={q.type}
                  alternatives={q.alternatives}
                  index={idx}
                  onNewAlternative={onNewAlternative}
                  onAlternativeChange={onAlternativeChange}
                  removable={this.props.questions.length !== 1}
                  deleteQuestion={deleteSelectedQuestion}
                  deleteAlternative={deleteAlternative}
                  onBodyChange={onBodyChange}
                  onChange={onQuestionTypeChange}
                  style={{marginTop: "50px", marginBottom: "10px"}}
                />
              );
            }
            return (
              <ReadOnlyQuestion
                body={q.body}
                key={idx}
                type={q.type}
                alternatives={q.alternatives}
                onSelectQuestion={onSelectQuestion}
                style={{marginTop: "10px", marginBottom: "10px"}}
                index={idx}
              />
            );
          })}
          <Fab color="primary" aria-label="Add" style={{marginTop: "10px"}} onClick={onNewQuestion}>
            <AddIcon />
          </Fab>
        </div>
      </div>
    );
  }
}

class ReadOnlyQuestion extends Component {
  render() {
    return (
      <div
        style={{marginTop: "10px", marginBottom: "10px"}}
        onClick={() => this.props.onSelectQuestion(this.props.index)}
      >
        <div>
          <TextField
            margin="normal"
            value={this.props.body}
            onChange={this.onBodyChange}
            multiline
            disabled
            InputProps={{
              disableUnderline: true
            }}
            fullWidth
          />
        </div>
        {this.props.type === "WrittenQuestion" ? <TextField
              label="Texto"
              placeholder="Texto"
              disabled
              margin="normal"
            /> : this.props.alternatives.map(el => <Alternative text={el.body} type={this.props.type}/>)}
      </div>
    );
  }
}

class Question extends Component {
  constructor(props) {
    super(props);
    this.onAlternativeChange = this.onAlternativeChange.bind(this);
  }

  onAlternativeChange(altIdx, body) {
    this.props.onAlternativeChange(this.props.index, altIdx, body);
  }
  render() {
    const { deleteQuestion, onChange } = this.props;
    return (
      <Card
        style={{marginTop: "10px", marginBottom: "10px"}}
      >
        <CardContent>
          <div style={{position: 'relative', display: 'inline-block', width:'100%'}}>
            <TextField
              placeholder="Pergunta"
              margin="normal"
              multiline
              fullWidth
              value={this.props.body}
              onChange={this.props.onBodyChange}
            />
            <Select
              value={this.props.type}
              onChange={onChange}
            >
              <MenuItem value={"MultipleChoiceQuestion"}>Múltipla Escolha</MenuItem>
              <MenuItem value={"CheckboxQuestion"}>Opções</MenuItem>
              <MenuItem value={"WrittenQuestion"}>Escrita</MenuItem>
            </Select>
            {this.props.removable &&
             <Tooltip title="Remover Questão">
               <IconButton
                 style={{position: "absolute", top: 10, right: 0}}
                 onClick={deleteQuestion}
               >
                 <DeleteIcon />
               </IconButton>
             </Tooltip>
            }
          </div>
          { this.props.type !== "WrittenQuestion" ? <AlternativesBox
          type={this.props.type}
          alternatives={this.props.alternatives}
          onAlternativeChange={this.onAlternativeChange}
          onNewAlternative={this.props.onNewAlternative}
          deleteAlternative={this.props.deleteAlternative}
                                                          />
            :
            <TextField
              label="Texto"
              placeholder="Texto"
              disabled
              margin="normal"
            />
          }
        </CardContent>

      </Card>
    );
  }
}

class AlternativesBox extends Component {
  render() {
    return (
      <div>
        {this.props.alternatives.map(
          (el, idx) =>
            <Alternative
              type={this.props.type}
              key={idx}
              text={el.body}
              removable={this.props.alternatives.length !== 1}
              selectable
              onChange={this.props.onAlternativeChange}
              index={idx}
              onDelete={() => this.props.deleteAlternative(idx)}/>
          )}
        <div>
          {this.props.type === "MultipleChoiceQuestion" ? <Radio disabled/> : <Checkbox disabled value="checkedD" />}
          <span style={{color: "#bbb", cursor: "pointer"}} onClick={this.props.onNewAlternative}>
            Adicionar opção
          </span>
        </div>
      </div>
    );
  }
}

class Alternative extends Component {
  render() {
    return (
      <div
        style={this.props.selectable && {cursor: "pointer", display: "flex", justifyContent: "space-between"}}
      >
        <div>
          {this.props.type === "MultipleChoiceQuestion" ? <Radio disabled/> : <Checkbox disabled value="checkedD" />}
          <InputBase
            placeholder="Alternativa"
            value={this.props.text}
            onChange={event => this.props.onChange(this.props.index, event.target.value)}
          />

        </div>
        {this.props.selectable && this.props.removable &&
         <Tooltip title="Remover alternativa">
           <IconButton onClick={this.props.onDelete}>
             <CancelIcon/>
           </IconButton>
         </Tooltip>
        }
      </div>
    );
  }
}



class Answers extends Component {
  render() {
    console.log("link", `${API_HOST}${this.props.link}`);
    if(!this.props.link) {
      return (
        <div>
          Carregando...
        </div>
      );
    }
    return (
      <div>
        <a
          href={`${API_HOST}${this.props.link}`}
          download
          style={{textDecoration: 'none'}}
        >
          <Button
            color="primary"
            variant="contained"
            size="large"
            style={{marginTop: 30}}
          >
            Exportar Planilha
          </Button>
        </a>
      </div>
    );
  }
}

class Pollsters extends Component {
  render () {
    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={this.props.allPollsters}
              onChange={this.props.onChangeAllPollsters}
              value="allPollsters"
              color="primary"/>
          }
          label="Incluir todos os pesquisadores"
        />
        
        <div style={{ height: 60, marginBottom: 20, marginTop: 20, display: 'flex', alignItems: 'center' }}>
          <Fab
            size="small"
            color="primary"
            onClick={this.props.toggleShowInput}
            disabled={this.props.allPollsters}> <AddIcon/> </Fab>
          {
            this.props.showInput && !this.props.allPollsters &&
            <div style={{ marginLeft: 20 }}>
              <form onSubmit={this.props.addPollster}>
                <div style={{ display: 'flex' }}>
                  <AutoCompletePollsters
                    value={this.props.pollster}
                    suggestions={this.props.pollstersSuggestions} 
                    handleChangeValue={this.props.onChangePollster}
                    onInputValueChange={this.props.onChangeInputPollster}
                  />
                  <IconButton
                    type="submit"
                    disabled={this.props.allPollsters}
                    size="small"> 
                    <SendIcon/>
                  </IconButton>
                </div>
              </form>
            </div>
          }
        </div>

        <Paper style={{ 
          width: 500,
          pointerEvents: this.props.allPollsters ? 'none' : 'auto',
          opacity: this.props.allPollsters ? 0.5 : 1 }}>
          <List
            style={{ maxHeight: 400, overflow: 'auto', position: 'relative' }}
            subheader={
              this.props.pollsters.length > 0 ?
              <ListSubheader style={{ backgroundColor: '#fff' }}>
                { this.props.pollsters.length } 
                { this.props.pollsters.length === 1 ? ' pesquisador' : ' pesquisadores' }
              </ListSubheader>
              :
              null}
          >
            
            {
              this.props.pollsters.length === 0 &&
              <ListItem key={ 0 }>
                <ListItemText secondary="Nenhum pesquisador adicionado" />
              </ListItem>
            }
            {
              this.props.pollsters.map( pollster => 
                <ListItem key={ pollster.id }>
                  <ListItemText primary={ pollster.name } secondary={ pollster.email }/>
                  <ListItemSecondaryAction>
                    <IconButton
                      disabled={this.props.allPollsters}
                      size="small"
                      onClick={ () => { this.props.removePollster(pollster.id)} }> 
                      <ClearIcon/>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            }
        </List>
      </Paper>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(SimpleTabs));
