const reEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

class FormValidator {
    isEmpty(value) {
        return value.trim().length === 0;
    } 

    maxLength(value, length) {
        return value.length > length;
    }

    validateName(name) {
        let response = { error: false, message: '' };
        if (this.isEmpty(name)) {
            response.error = true;
            response.message = 'Campo obrigatório';
        }
        return response;
    }

    validateEmail(email) {
        let response = { error: false, message: '' };
        if (this.isEmpty(email)) {
            response.error = true;
            response.message = 'Campo obrigatório';
        } else {
            if (!reEmail.test(email)) {
                response.error = true;
                response.message = 'Email inválido';
            }
        }
        return response;
    }
    
    validatePassword(password) {
        let response = { error: false, message: '' };
        if (this.isEmpty(password)) {
            response.error = true;
            response.message = 'Campo obrigatório';
        }
        return response;
    }

    validateConfirmPassword(confirmPassword, password) {
        let response = { error: false, message: '' };
        if (this.isEmpty(confirmPassword)) {
            response.error = true;
            response.message = 'Campo obrigatório'
        } else {
            if (confirmPassword !== password) {
                response.error = true;
                response.message = 'Senhas não coincidem'
            }
        }
        return response;
    }

}

export default new FormValidator();