import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Link, withRouter } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Tooltip from '@material-ui/core/Tooltip';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment-timezone';
const styles = theme => ({
  addIcon: {
    marginLeft: "10px",
    cursor: "pointer",
    color: "blue"
  }
});

const GET_MESSAGES = gql`
  {
    messages {
      id
      body
      author {
        name
      }
      title
      createdAt
      sentAt
    }
  }
`;

class Messages extends Component {
  constructor(props) {
    super(props);
    this.handleAddMessageClick = this.handleAddMessageClick.bind(this);
  }

  handleAddMessageClick() {
    window.history.push('/app/mensagens/nova');
  }

  formatDateStr(s) {
    return moment(s).format("DD/MM/YYYY HH:mm");
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Typography variant="h4" gutterBottom component="h2">
          Mensagens
        </Typography>
        <Link to="/app/mensagens/nova">
          <Tooltip title="Criar mensagem">
            <AddCircleIcon
              className={classes.addIcon}
            />
          </Tooltip>
        </Link>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Título</TableCell>
              <TableCell align="left">Conteúdo</TableCell>
              <TableCell align="left">Autor</TableCell>
              <TableCell align="left">Data de criação</TableCell>
              <TableCell align="left">Data de envio</TableCell>
              <TableCell align="left">Editar</TableCell>
            </TableRow>
          </TableHead>
            <Query query={GET_MESSAGES}>
              {({ loading, error, data }) => {
                if (loading) return (
                  <TableBody>
                    <TableRow>
                      <TableCell>Carregando...</TableCell>
                    </TableRow>
                  </TableBody>
                );
                if (error) return `Erro! ${error.message}`;
                let messages = data.messages;
                if(!messages.length) {
                  return <TableBody></TableBody>;
                }
                return (
                  <TableBody>{
                  messages.map(message => (
                    <TableRow key={message.id}>
                      <TableCell component="th" style={message.title ? {fontWeight: "bold"} : {}} scope="row">{message.title || "(Sem título)"}</TableCell>
                      <TableCell align="left">{message.body.length > 60 ? `${message.body.substring(0, 60)}...` : message.body}</TableCell>
                      <TableCell align="left">{message.author.name}</TableCell>
                      <TableCell align="left">{this.formatDateStr(message.createdAt)}</TableCell>
                      <TableCell align="left">{message.sentAt ? this.formatDateStr(message.sentAt) : 'Não enviada'}</TableCell>
                      <TableCell align="left"><Tooltip title="Editar mensagem"><Link to={`/app/mensagens/${message.id}`}><EditIcon style={{color: "#000"}}/></Link></Tooltip></TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
                );
              }}
            </Query>
        </Table>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Messages));
