import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import client from './api-client';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';


const styles = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
});

const CREATE_AND_SEND_MESSAGE = gql`
  mutation CreateAndSendMessage($body: String!, $title: String) {
    createAndSendMessage(input: {body: $body, title: $title}) {
      message {
        body
      }
    }
  }
`;

const CREATE_MESSAGE = gql`
  mutation CreateMessage($body: String!, $title: String) {
    createMessage(input: {body: $body, title: $title}) {
      message {
        body
        createdAt
        sentAt
      }
    }
  }
`;

const UPDATE_AND_SEND_MESSAGE = gql`
  mutation UpdateAndSendMessage($id: Int!, $body: String!, $title: String) {
    updateAndSendMessage(input: {id: $id, body: $body, title: $title}) {
      message {
        body
      }
    }
  }
`;

const UPDATE_MESSAGE = gql`
  mutation UpdateMessage($id: Int!, $body: String!, $title: String) {
    updateMessage(input: {id: $id, body: $body, title: $title}) {
      message {
        body
      }
    }
  }
`;

const GET_MESSAGE = gql`
  query Message($id: Int!) {
    message(id: $id) {
      body
      title
      createdAt
      sentAt
    }
  }
`;

class NewMessage extends Component {
  constructor(props) {
    super(props);
    this.createMessage = this.createMessage.bind(this);
    this.createAndSendMessage = this.createAndSendMessage.bind(this);
    this.updateMessage = this.updateMessage.bind(this);
    this.updateAndSendMessage = this.updateAndSendMessage.bind(this);
    this.handleBodyChange = this.handleBodyChange.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.state = {
      message: {}
    };
  }

  async componentDidMount() {
    let { messageId } = this.props;
    if(messageId) {
      let res = await client.query({
        query: GET_MESSAGE,
        variables: {
          id: messageId
        }
      });
      this.setState((state, props) => {
        let message = state.message;
        message.body = res.data.message.body;
        message.title = res.data.message.title;
        message.sentAt = res.data.message.sentAt;
        return {
          message: message,
        };
      });
    }
  }

  handleBodyChange(event) {
    let val = event.target.value;
    this.setState((state, props) => {
      let message = state.message;
      message.body = val;
      return {
        message: message
      };
    });
  }

  handleTitleChange(event) {
    let val = event.target.value;
    this.setState((state, props) => {
      let message = state.message;
      message.title = val;
      return {
        message: message
      };
    });
  }

  async createAndSendMessage() {
    const { body, title } = this.state.message;
    try {
      await client.mutate({
        mutation: CREATE_AND_SEND_MESSAGE,
        variables: {
          body: body,
          title: title
        }
      });
      this.props.history.push('/app/mensagens');
    } catch(e) {
      console.log("Erro", e);
    };    
  }

  async updateAndSendMessage() {
    const { body, title } = this.state.message;
    const { messageId } = this.props;
    try {
      await client.mutate({
        mutation: UPDATE_AND_SEND_MESSAGE,
        variables: {
          body: body,
          id: messageId,
          title: title
        }
      });
      this.props.history.push('/app/mensagens');
    } catch(e) {
      console.log("Erro", e);
    };
  }

  async createMessage() {
    const { body, title } = this.state.message;
    try {
      await client.mutate({
        mutation: CREATE_MESSAGE,
        variables: {
          body: body,
          title: title
        }
      });
      this.props.history.push('/app/mensagens');
    } catch(e) {
      console.log("Erro", e);
    }
  }

  async updateMessage() {
    const { body, title } = this.state.message;
    const { messageId } = this.props;
    console.log(this.props);
    try {
      await client.mutate({
        mutation: UPDATE_MESSAGE,
        variables: {
          body: body,
          title: title,
          id: messageId
        }
      });
      this.props.history.push('/app/mensagens');
    } catch(e) {
      console.log("Erro", e);
    };
  }

  render() {
    const { classes, messageId } = this.props;
    return (
      <div>
        <Typography variant="h4" gutterBottom component="h2">
          Nova Mensagem
        </Typography>
        <TextField
          label="Título"
          placeholder="Título"
          value={this.state.message.title || ''}
          onChange={this.handleTitleChange}
          disabled={this.state.message.sentAt}
        />
        <TextField
          id="standard-multiline-static"
          label="Conteúdo"
          value={this.state.message.body || ''}
          onChange={this.handleBodyChange}
          multiline
          placeholder="Digite a mensagem..."
          margin="normal"
          fullWidth
          disabled={this.state.message.sentAt}
        />
        <div>
          <Button
            className={classes.margin}
            color="primary"
            variant="contained"
            size="large"
            disabled={!!this.state.message.sentAt}
            onClick={messageId ? this.updateAndSendMessage : this.createAndSendMessage}
          >
            Enviar
          </Button>
          <Button
            className={classes.margin}
            color="primary"
            variant="contained"
            size="large"
            disabled={!!this.state.message.sentAt}
            onClick={messageId ? this.updateMessage : this.createMessage}
          >
            Salvar
          </Button>
          <Link to="/app/mensagens">
            <Button
              className={classes.margin}
              color="primary"
              variant="contained"
              size="large"
              
            >
              Cancelar
            </Button>
          </Link>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(NewMessage));

